import React, { useState, useEffect, useRef, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyBGuJBV9PrgPYyhyBn3d47OEMKMff0lM_8",
  authDomain: "trumpcircle-9b1ef.firebaseapp.com",
  databaseURL: "https://trumpcircle-9b1ef-default-rtdb.firebaseio.com",
  projectId: "trumpcircle-9b1ef",
  storageBucket: "trumpcircle-9b1ef.firebaseapp.com",
  messagingSenderId: "358737835546",
  appId: "1:358737835546:web:1f48733be8b11c137043a3"
};

// Background component for falling bricks
const Background = React.memo(() => {
  const bricks = useMemo(() => Array.from({ length: 15 }, (_, i) => ({
    delay: Math.random() * 5,
    initialX: Math.random() * 100,
    color: ['#FF0000', '#0000FF', '#008000', '#FFD700', '#FFA500'][Math.floor(Math.random() * 5)],
    size: 20 + Math.random() * 30,
    rotation: Math.random() * 360,
    key: i
  })), []);

  return (
    <>
      <style jsx>{`
        @keyframes fall {
          0% {
            transform: translateY(-20%) rotate(0deg);
          }
          100% {
            transform: translateY(120vh) rotate(360deg);
          }
        }

        .brick {
          box-shadow: 0 3px 6px rgba(0,0,0,0.2);
          will-change: transform;
        }
      `}</style>

      {bricks.map(brick => (
        <FallingBrick
          key={brick.key}
          delay={brick.delay}
          initialX={brick.initialX}
          color={brick.color}
          size={brick.size}
          rotation={brick.rotation}
        />
      ))}
    </>
  );
});

const FallingBrick = React.memo(({ delay, initialX, color, size, rotation }) => {
  return (
    <div
      className="absolute"
      style={{
        left: `${initialX}%`,
        animation: `fall ${7 + Math.random() * 5}s linear ${delay}s infinite`,
        transform: `rotate(${rotation}deg)`,
        willChange: 'transform',
      }}
    >
      <div
        className="brick"
        style={{
          width: `${size}px`,
          height: `${size * 0.6}px`,
          backgroundColor: color,
          position: 'relative',
        }}
      >
        <div className="studs" style={{ display: 'flex', justifyContent: 'space-around', padding: '2px' }}>
          {[...Array(2)].map((_, i) => (
            <div
              key={i}
              style={{
                width: `${size * 0.25}px`,
                height: `${size * 0.25}px`,
                backgroundColor: color,
                borderRadius: '50%',
                position: 'relative',
                top: `-${size * 0.2}px`,
                boxShadow: 'inset 0 2px 3px rgba(255,255,255,0.3), inset 0 -2px 3px rgba(0,0,0,0.2)'
              }}
            />
          ))}
        </div>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(135deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 50%, rgba(0,0,0,0.2) 100%)'
        }} />
      </div>
    </div>
  );
});

const MarketCapTracker = () => {
  const [marketCap, setMarketCap] = useState(0);
  const [previousMarketCap, setPreviousMarketCap] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(200);
  const [expression, setExpression] = useState('neutral');
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const headRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (headRef.current) {
        const headRect = headRef.current.getBoundingClientRect();
        const headCenterX = headRect.left + headRect.width / 2;
        const headCenterY = headRect.top + headRect.height / 2;
        setMousePos({
          x: e.clientX - headCenterX,
          y: e.clientY - headCenterY
        });
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);
    const marketCapRef = ref(database, 'marketCap');

    const unsubscribe = onValue(marketCapRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setPreviousMarketCap(marketCap);
        setMarketCap(data.value);
        setLastUpdated(data.lastUpdated);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (marketCap > previousMarketCap) {
      setExpression('happy');
      const timer = setTimeout(() => {
        setExpression('neutral');
      }, 3000);
      return () => clearTimeout(timer);
    } else if (marketCap < previousMarketCap) {
      setExpression('sad');
      const timer = setTimeout(() => {
        setExpression('neutral');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [marketCap, previousMarketCap]);

  useEffect(() => {
    const baseSize = 200;
    const scale = Math.min(marketCap / 250000, 8);
    const targetSize = Math.min(baseSize * (1 + scale), window.innerHeight * 0.9);

    let animationFrame;
    let startTime = null;
    const duration = 500;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = (timestamp - startTime) / duration;

      if (progress < 1) {
        const currentSize = size + (targetSize - size) * easeInOutCubic(progress);
        setSize(currentSize);
        animationFrame = requestAnimationFrame(animate);
      } else {
        setSize(targetSize);
      }
    };

    animationFrame = requestAnimationFrame(animate);

    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [marketCap]);

  const easeInOutCubic = (x) => {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  };

  const formatLastUpdated = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleTimeString();
  };

  const getEyePosition = () => {
    const maxOffset = 3;
    const xOffset = Math.min(Math.max(mousePos.x / 100, -1), 1) * maxOffset;
    const yOffset = Math.min(Math.max(mousePos.y / 100, -1), 1) * maxOffset;
    return { x: xOffset, y: yOffset };
  };

  const renderExpression = () => {
    const eyePos = getEyePosition();
    const eyeStyle = {
      width: '12px',
      height: '12px',
      background: 'black',
      borderRadius: '50%',
      transform: `translate(${eyePos.x}px, ${eyePos.y}px)`,
      transition: 'transform 0.1s ease-out'
    };

    const eyebrowRotation = Math.min(Math.max(mousePos.y / 200, -0.5), 0.5) * 15;

    switch (expression) {
      case 'happy':
        return (
          <>
            <div className="absolute flex justify-between" style={{
              top: '35%',
              left: '25%',
              width: '50%',
              height: '4px',
              zIndex: 2
            }}>
              <div style={{
                width: '35%',
                height: '100%',
                background: 'black',
                borderRadius: '2px',
                transform: `rotate(${-eyebrowRotation}deg)`,
                transition: 'transform 0.1s ease-out'
              }} />
              <div style={{
                width: '35%',
                height: '100%',
                background: 'black',
                borderRadius: '2px',
                transform: `rotate(${eyebrowRotation}deg)`,
                transition: 'transform 0.1s ease-out'
              }} />
            </div>
            <div className="absolute flex justify-between" style={{
              top: '42%',
              left: '25%',
              width: '50%',
              height: '8%',
              zIndex: 2
            }}>
              <div style={eyeStyle} />
              <div style={eyeStyle} />
            </div>
            <div className="absolute" style={{
              bottom: '35%',
              left: '35%',
              width: '30%',
              height: '12%',
              borderBottom: '3px solid black',
              borderRadius: '0 0 100px 100px'
            }} />
          </>
        );
      case 'sad':
        return (
          <>
            <div className="absolute flex justify-between" style={{
              top: '35%',
              left: '25%',
              width: '50%',
              height: '4px',
              zIndex: 2
            }}>
              <div style={{
                width: '35%',
                height: '100%',
                background: 'black',
                borderRadius: '2px',
                transform: `rotate(${15 - eyebrowRotation}deg)`,
                transition: 'transform 0.1s ease-out'
              }} />
              <div style={{
                width: '35%',
                height: '100%',
                background: 'black',
                borderRadius: '2px',
                transform: `rotate(${-15 + eyebrowRotation}deg)`,
                transition: 'transform 0.1s ease-out'
              }} />
            </div>
            <div className="absolute flex justify-between" style={{
              top: '42%',
              left: '25%',
              width: '50%',
              height: '8%',
              zIndex: 2
            }}>
              <div style={eyeStyle} />
              <div style={eyeStyle} />
            </div>
            <div className="absolute" style={{
              top: '60%',
              left: '35%',
              width: '30%',
              height: '12%',
              borderTop: '3px solid black',
              borderRadius: '100px 100px 0 0'
            }} />
          </>
        );
      default:
        return (
          <>
            <div className="absolute flex justify-between" style={{
              top: '35%',
              left: '25%',
              width: '50%',
              height: '4px',
              zIndex: 2
            }}>
              <div style={{
                width: '35%',
                height: '100%',
                background: 'black',
                borderRadius: '2px',
                transform: `rotate(${-eyebrowRotation}deg)`,
                transition: 'transform 0.1s ease-out'
              }} />
              <div style={{
                width: '35%',
                height: '100%',
                background: 'black',
                borderRadius: '2px',
                transform: `rotate(${eyebrowRotation}deg)`,
                transition: 'transform 0.1s ease-out'
              }} />
            </div>
            <div className="absolute flex justify-between" style={{
              top: '42%',
              left: '25%',
              width: '50%',
              height: '8%',
              zIndex: 2
            }}>
              <div style={eyeStyle} />
              <div style={eyeStyle} />
            </div>
            <div className="absolute" style={{
              bottom: '35%',
              left: '35%',
              width: '30%',
              height: '3px',
              background: 'black',
              borderRadius: '100px',
              transform: 'rotate(10deg)'
            }} />
          </>
        );
    }
  };

  return (
    <div className="min-h-screen relative overflow-hidden bg-yellow-100">
      <style jsx>{`
        @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.05); }
          100% { transform: scale(1); }
        }

        .minifig-head {
          position: relative;
          background: #FFD700;
          border-radius: 0;
          overflow: visible;
        }

        .minifig-head::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(135deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 50%, rgba(0,0,0,0.1) 100%);
        }

        .minifig-stud {
          position: absolute;
          top: -20%;
          left: 50%;
          transform: translateX(-50%);
          width: 40%;
          height: 20%;
          background: #FFD700;
          border-radius: 50% 50% 0 0;
        }

        .minifig-stud::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 50%;
          background: rgba(255,255,255,0.2);
          border-radius: 50% 50% 0 0;
        }

        .minifig-neck {
          position: absolute;
          bottom: -15%;
          left: 50%;
          transform: translateX(-50%);
          width: 50%;
          height: 15%;
          background: #FFD700;
        }
      `}</style>

      <Background />

      <div className="absolute top-4 right-4 z-10">
        <a
          href="https://pump.fun/F8h9mefQEptYmAgkpo1qZMWNv3J9ZJoWt7eRJKxhpump"
          target="_blank"
          rel="noopener noreferrer"
          className="px-8 py-3 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 text-black rounded-full
            hover:from-yellow-500 hover:via-yellow-600 hover:to-yellow-700 transition-all duration-300
            font-bold text-lg shadow-lg hover:shadow-yellow-500/50
            transform hover:scale-105"
          style={{
            animation: 'pulse 2s infinite'
          }}
        >
          Buy Now
        </a>
      </div>

      <div className="flex items-center justify-center min-h-screen">
        {!isLoading && (
          <div className="relative">
            <div
              ref={headRef}
              className="minifig-head"
              style={{
                width: `${size}px`,
                height: `${Math.round(size * 1.2)}px`,
              }}
            >
              <div className="minifig-stud" />
              <div className="minifig-neck" />
              {renderExpression()}
              <div className="absolute -bottom-16 left-0 right-0 text-center text-black" style={{ zIndex: 4 }}>
                <div className="text-xl font-bold">
                  ${marketCap.toLocaleString(undefined, {maximumFractionDigits: 0})}
                </div>
                <div className="text-sm opacity-75">
                  Last updated: {formatLastUpdated(lastUpdated)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketCapTracker;
